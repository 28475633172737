import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import ReactLoading from 'react-loading';
import _ from 'lodash';
import FilterBar from './filterbar'
import CustomMap from './custom_map';
import { default as TopMenu } from './top_menu';
import { default as TopBar } from './top_bar';
import { ws_date, ws_parseLocation } from '../utils/ws_utils_mpma';
import { fetchEventsByPeriode } from '../actions/index';
export const DISPLAY_LIST = 'liste';
export const DISPLAY_MAP = 'carte';


class EventsMap extends Component {
  componentDidMount = () =>{
    const { selection, tab } = ws_parseLocation(this.props.match.path);
    const { begin, end } = ws_date(tab);
    this.props.fetchEventsByPeriode(begin, end, selection, 200, 0);
  }
  constructor(props) {
    super(props);
    this.state={
      lat: 51.505,
      lng: -0.09,
      zoom: 13
    };
  }
  onBack =() =>{
    this.props.history.goBack();
  }
  getInnerRef = (ref) =>{
    this.innerRef = ref;
  }
  getLocation = () =>{
    this.innerRef && this.innerRef.getLocation();
  }
  render = () =>{
    const {  getLocation } = this;
    const { selection, tab } = ws_parseLocation(this.props.match.path);
    const { begin } = ws_date(tab);
    getLocation();
    let tmpmarkers = [];
    _.map(this.props.events.currentState, function(day) {
      for (var i = 0; i < day.length; i++) {
        var event = day[i];
        if (event.localisation.geolocalisation && event.localisation.geolocalisation.geoJson && event.localisation.geolocalisation.geoJson.coordinates) {
          let marker = {};
          marker.key = event.id;
          marker.position = [event.localisation.geolocalisation.geoJson.coordinates[1], event.localisation.geolocalisation.geoJson.coordinates[0]];
          marker.selectionId = selection;
          marker.tab = tab;
          var libelle = 'libelleFr'; // + require('browser-locale')().capitalize();
          const private_data = (event.donneesPrivees) ? event.donneesPrivees: '';
          const TitreAgendaMpmPwa = (private_data) ? private_data.find((e) => e.nomTechnique == '1206_TitreAgendaMpmPwa') : '';
          const private_title = (TitreAgendaMpmPwa) ? TitreAgendaMpmPwa.descriptif[libelle]: '';
          const nom = private_title ? private_title : event.nom[libelle];
          marker.children = nom;
          marker.date = begin;
          marker.target = '_self';
          marker.class = 'link';
          tmpmarkers.push(marker);
        }
      }
    });
    const markers = uniqBy(tmpmarkers, JSON.stringify);
    const position = [43.208154, 6.274601];
    return (
      <div>
        <TopMenu go={ url => this.props.history.push(url) } back = {this.onBack} />
        <TopBar 
            tab={tab} 
            selectionId={selection} 
            go={ url => this.props.history.push(url) } 
        />
        {this.props.events.title &&
            <h1 className="eventTittle">{this.props.events.title}</h1>
        }
        <FilterBar 
            display={DISPLAY_MAP}
            selectionId={selection}
            tab={tab}
        />
        {markers.length == 0 && this.props.events.apidown !== true &&
         <div className="loadingDiv">
            <ReactLoading className="spinner" type="spinningBubbles" color="#B59C1E"  width="75" />
        </div>
        }
        {markers.length == 0 && this.props.events.apidown === true &&
        <div class="network-error">
            <p><FormattedMessage id="error.apidaedown.0"/></p> 
            <p><FormattedMessage id="error.apidaedown.1"/></p>
        </div>
        }
        {markers.length > 0 &&
        <div className="laeflet">
            <CustomMap center={position} zoom={this.state.zoom} markers={markers} />
        </div>
        }
    </div>
    );
  }
}

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

function uniqBy(a, key) {
  var seen = {};
  return a.filter(function(item) {
    var k = key(item);
    return seen.hasOwnProperty(k) ? false : (seen[k] = true);
  });
}

function mapStateToProps({ events }) {
  return { events };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchEventsByPeriode }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsMap);
