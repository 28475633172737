import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchObjetLie } from '../actions/index';
const defaultImage = 'https://sortir.mpmtourisme.com/assets/img/defaultImage.svg';
class ObjetLies extends Component {
  constructor(props){
    super(props);
  }
  componentDidMount = () =>{
    var ids=[];
    this.props.events.forEach(function(event) {
      ids.push(event.objetTouristique.id);
    });
    this.props.fetchObjetLie(ids, this.props.selection);
  }
  renderTeaser= (objetLie) =>{
    const libelle = 'libelleFr';
      if(objetLie != undefined){
          const nom = objetLie.nom[libelle] ? objetLie.nom[libelle] : "";
          const categories = objetLie.informationsFeteEtManifestation && objetLie.informationsFeteEtManifestation.categories ? objetLie.informationsFeteEtManifestation.categories[0] : false;
          const themes = objetLie.informationsFeteEtManifestation && objetLie.informationsFeteEtManifestation.themes ? objetLie.informationsFeteEtManifestation.themes[0] : false;
          var url = `/${this.props.selection}/${this.props.tab}/detail/${objetLie.id}`;
          var illustrations = objetLie.illustrations ? objetLie.illustrations : "";
          var illustration = defaultImage;
          var alt = nom ? nom[libelle] : "";
          if (Array.isArray(illustrations) && illustrations.length > 0) {
            const firstIllustration = illustrations[0].traductionFichiers;
            if (Array.isArray(firstIllustration) && firstIllustration.length > 0) {
              if (firstIllustration[0].fileName) {
                alt = firstIllustration[0].fileName;
              }
              if (firstIllustration[0].urlDiaporama) {
                illustration = encodeURI(firstIllustration[0].urlDiaporama.replace('http:', 'https:'));
              }
            }
          }
        return(
            <div className="col-xs-6 col-lg-4 teaser" key={objetLie.id}>
                <a className="event-link" name={nom} aria-label={nom} href={url}>
                    <img width="100%" decoding="async" loading="lazy" alt={alt} src={`https://images.weserv.nl/?w=303&h=225&fit=cover&l=9&il&output=jpg&q=75&url=${illustration}`} /> 
                </a>
                <a className="event-link-2" name={nom} aria-label={nom} href={url}>
                    {categories &&
                        <div className="categorie">
                            {this.props.renderCategory(categories)}
                        </div>
                    }
                    {themes &&
                        <div className="theme">
                            {this.props.renderTheme(themes)}
                        </div>
                    }
                    <p className="description">{nom}</p>
                </a>
            </div>
       );
      }else{
        return null;
      }
  }
  render = () =>{
     var objetsLies=[];
      if(this.props.objetLies){
        var propsObjets = this.props.objetLies.numFound>0 ? Object.values(this.props.objetLies.objetsTouristiques) : [];
        objetsLies = Array.from(new Set(propsObjets.map(s => s[0].id)))
          .map(id => {
            return {
              id:id,
              illustrations: propsObjets.find(s => s[0].id === id)[0].illustrations,
              informationsFeteEtManifestation: propsObjets.find(s => s[0].id === id)[0].informationsFeteEtManifestation,
              localisation: propsObjets.find(s => s[0].id === id)[0].localisation,
              nom: propsObjets.find(s => s[0].id === id)[0].nom,
              type: propsObjets.find(s => s[0].id === id)[0].type
            };
          });
      }
      return(
        <div className="programme">
          {(this.props.objetLies && this.props.objetLies.numFound>0) &&
            <div className="programme-container">
              <h3>{this.props.type}</h3>
              {objetsLies.map(this.renderTeaser)}
            </div>
          }
        </div>
      );
  }
}
function mapStateToProps({ events }) {
  return events ;
}
export default withRouter(connect(mapStateToProps, { fetchObjetLie })(ObjetLies));
