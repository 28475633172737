import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setDate } from '../actions/index';
import moment from 'moment';


// Include the locale utils designed for moment
import MomentLocaleUtils, {
  formatDate,
  parseDate,
}
from 'react-day-picker/moment';


// Make sure moment.js has the required locale data
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import 'moment/locale/de';

import { languageWithoutRegionCode } from '../index';

class LocalizedDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDay: this.props.events.date || this.props.selectedDate,
    };
  }
  handleDayChange = (day) =>{
    this.props.setDate(moment(day));
    this.props.onDateSelect(moment(day));
  }

  render = () =>{
    const { selectedDay } = this.state;
    return (
      <div>
          <DayPickerInput
          inputProps={{ readOnly: true , }}
          formatDate={formatDate}
          parseDate={parseDate}
          format="L"
          placeholder={`${formatDate(selectedDay, 'L', languageWithoutRegionCode)}`}
          dayPickerProps={{
            locale: languageWithoutRegionCode,
            localeUtils: MomentLocaleUtils,
            keepFocus: false
          }}
          onDayChange={this.handleDayChange}
        />
      </div>
    );
  }
}

function mapStateToProps({ events }) {
  return { events };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setDate }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LocalizedDatePicker);
