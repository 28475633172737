import axios from 'axios';
import { ws_selectionByPath } from '../utils/ws_utils_mpma';
//const APIDAE_KEY = 'jUoMPyJi';
//const PROJECT_ID = '1170';
const PROJECT_ID = '3195';
const APIDAE_KEY = 'PCM31JCr';
const APIDAE_URL = `https://api.apidae-tourisme.com/api/v002/agenda/detaille/list-objets-touristiques`;
const FETCH_EVENT_URL = `https://api.apidae-tourisme.com/api/v002/objet-touristique/get-by-id`;
const FETCH_EVENTS = 'FETCH_EVENTS';
const FETCH_EVENTS_OK = 'FETCH_EVENTS_OK';
const FETCH_EVENTS_TIMEOUT = 'FETCH_EVENTS_TIMEOUT';
const FETCH_EVENT = 'FETCH_EVENT';
const FETCH_EVENT_OK = 'FETCH_EVENT_OK';
const FETCH_EVENT_TIMEOUT = 'FETCH_EVENT_TIMEOUT';
const FETCH_OBJETSLIE = 'FETCH_OBJETSLIE';
const FETCH_OBJETSLIE_OK = 'FETCH_OBJETSLIE_OK';
const FETCH_OBJETSLIE_TIMEOUT = 'FETCH_OBJETSLIE_TIMEOUT';
const FILTER_THEMATIC = 'FILTER_THEMATIC';
const FILTER_PLACE = 'FILTER_PLACE';
const FILTER_EVENTS = 'FILTER_EVENTS';
const SET_SELECTIONID = 'SET_SELECTIONID';
const SET_TITLE = 'SET_TITLE';
const SET_DATE = 'SET_DATE';
const RESTORE_STATE = 'RESTORE_STATE';
const locale = "fr";

function fetchEventsByFilters(dateDebut, dateFin, selection , filters, count = 200, first = 0) {
  let selectionId = ws_selectionByPath(selection);
  var url = `${APIDAE_URL}?query={"projetId":"${PROJECT_ID}","apiKey":"${APIDAE_KEY}","criteresQuery":"type:FETE_ET_MANIFESTATION" ,"count":"${count}", "first":"${first}", "dateDebut":"${dateDebut}","dateFin":"${dateFin}" , "selectionIds":[ ${selectionId} ],"responseFields": ["id", "nom", "donneesPrivees", "informationsFeteEtManifestation", "illustrations","localisation"], "locales":[ "${locale}" ]}`;
  console.log(dateDebut + ' - ' + dateFin + ' - ' + selection + ' - ' + filters + ' - ' + count + ' - ' + first);
  return (dispatch) => {
    dispatch({ type: FETCH_EVENTS });
    axios.get(url)
      .then((res) => {
        var currentState = res.data.objetsTouristiques;
        filters.forEach(function(filter) {
          switch (filter.type) {
            case FILTER_PLACE:
              currentState = filterPlace(currentState, filter.value);
              break;
            case FILTER_THEMATIC:
              currentState = filterThematic(currentState, filter.value);
              break;
            default:
              break;
          }
        });
        res.data.objetsTouristiques = currentState;
        dispatch({ type: FETCH_EVENTS_OK, payload: res });
      })
      .catch((error) => {
        dispatch({ type: FETCH_EVENTS_TIMEOUT, payload: error });
      });
  };
}

function fetchEventsByPeriode(dateDebut, dateFin, selection , count = 200, first = 0, append = false) {
  let selectionId = ws_selectionByPath(selection);
  const url = `${APIDAE_URL}?query={"projetId":"${PROJECT_ID}","apiKey":"${APIDAE_KEY}","criteresQuery":"type:FETE_ET_MANIFESTATION" ,"order":"DATE_OUVERTURE", "count":"${count}", "first":"${first}", "dateDebut":"${dateDebut}","dateFin":"${dateFin}" , "selectionIds":[ ${selectionId} ],"responseFields": ["id", "nom", "donneesPrivees", "informationsFeteEtManifestation", "illustrations","localisation"], "locales":[ "${locale}" ]}`;
  return (dispatch) => {
    dispatch({ type: FETCH_EVENTS, append: append });
    axios.get(url)
      .then((res) => {
        dispatch({ type: FETCH_EVENTS_OK, payload: res, append: append });
      })
      .catch((error) => {
        dispatch({ type: FETCH_EVENTS_TIMEOUT, payload: error});
      });
  };
}

function setSelection(selectionId) {
  return {
    type: SET_SELECTIONID,
    payload: selectionId
  };
}

function setDate(date) {
  return {
    type: SET_DATE,
    payload: date
  };
}

function setTitle(title) {
  return {
    type: SET_TITLE,
    payload: title
  };
}

function fetchEvent(date, id) {  
  const url = `${FETCH_EVENT_URL}/${id}?responseFields=id,nom,donneesPrivees,reservation,informations,presentation,localisation,ouverture,descriptionTarif,illustrations,informationsFeteEtManifestation,prestations,multimedias,liens,nomLieu&projetId=${PROJECT_ID}&apiKey=${APIDAE_KEY}&locales=${locale}`;
  return (dispatch) => {
    dispatch({ type: FETCH_EVENT });
    axios.get(url)
      .then((res) => {
         dispatch({ type: FETCH_EVENT_OK, payload: res });
      })
      .catch((error) => {
        dispatch({ type: FETCH_EVENT_TIMEOUT, payload: error });
      });
  };
}
function fetchObjetLie(ids, selection) {
  let selectionId = ws_selectionByPath(selection);
  const url = `${APIDAE_URL}?query={"projetId":"${PROJECT_ID}","apiKey":"${APIDAE_KEY}","criteresQuery":"type:FETE_ET_MANIFESTATION","identifiants":[${ids}],"responseFields": ["id", "nom", "donneesPrivees", "informationsFeteEtManifestation", "illustrations","localisation"], "locales":[ "${locale}" ]}`;
  console.log(url,'url');
  return (dispatch) => {
    dispatch({ type: FETCH_OBJETSLIE});
    axios.get(url)
      .then((res) => {
        dispatch({ type: FETCH_OBJETSLIE_OK, payload: res});
      })
      .catch((error) => {
        dispatch({ type: FETCH_OBJETSLIE_TIMEOUT, payload: error});
      });
  };
}


function filterEvent(filters, state) {
  var currentState = JSON.parse(JSON.stringify(state));
  filters.forEach(function(filter) {
    switch (filter.type) {

      case FILTER_PLACE:
        currentState = filterPlace(currentState, filter.value);
        break;
      case FILTER_THEMATIC:
        currentState = filterThematic(currentState, filter.value);
        break;

      default:
        break;
    }
  });
  return {
    type: FILTER_EVENTS,
    payload: currentState
  };
}

function filterPlace(currentState, value) {
  let filteredState = {};
  filteredState = JSON.parse(JSON.stringify(currentState));
  let LABEL_FILTRE_LIEUX = ["Tri par commune", "By place", "Sortierung nach Ort"];
  if (LABEL_FILTRE_LIEUX.includes(value)) {
    return filteredState;
  }
  else {
    for (let date in filteredState) {
      let events = filteredState[date];
      for (var i = events.length - 1; i >= 0; i--) {
        if (events[i].localisation.adresse.commune.nom !== value) {
          events.splice(i, 1);
        }
        else {}
      }
    }

    Object.keys(filteredState).forEach((key) => (filteredState[key].length == 0) && delete filteredState[key]);
    return JSON.parse(JSON.stringify(filteredState));
  }
}

function filterThematic(currentState, value) {
  let state = {};
  state = JSON.parse(JSON.stringify(currentState));
  let LABEL_FILTRE_THEME = ["Tri par thème", "By theme", "Sortierung nach Thema"];
  if (LABEL_FILTRE_THEME.includes(value)) {
    return state;
  }
  else {
    for (let date in state) {
      let events = state[date];
      for (var i = events.length - 1; i >= 0; i--) {
        if(value === 'Autour du vin'){
          if (Array.isArray(events[i].informationsFeteEtManifestation.themes)) {
            let filteredThematic = [];
            filteredThematic = events[i].informationsFeteEtManifestation.themes.filter(function(theme) {
              return theme.libelleFr === "Oenologie";
            });
  
            if (filteredThematic.length > 0) {
            }
            else {
              events.splice(i, 1);
            }
  
          }
          else {
            events.splice(i, 1);
          }
        }else{
          if (Array.isArray(events[i].informationsFeteEtManifestation.categories)) {
            let filteredThematic = [];
            filteredThematic = events[i].informationsFeteEtManifestation.categories.filter(function(category) {
              return category.libelleFr === value;
            });
  
            if (filteredThematic.length > 0) {}
            else {
              events.splice(i, 1);
            }
  
          }
          else {
            events.splice(i, 1);
          }
        }
      }
    }
    Object.keys(state).forEach((key) => (state[key].length == 0) && delete state[key]);
    return JSON.parse(JSON.stringify(state));
  }
}

export {
  FETCH_EVENTS,
  FETCH_EVENTS_OK,
  FETCH_EVENTS_TIMEOUT,
  FETCH_EVENT,
  FETCH_EVENT_OK,
  FETCH_EVENT_TIMEOUT,
  FILTER_THEMATIC,
  FILTER_PLACE,
  FILTER_EVENTS,
  SET_SELECTIONID,
  SET_TITLE,
  SET_DATE,
  FETCH_OBJETSLIE,
  FETCH_OBJETSLIE_OK,
  FETCH_OBJETSLIE_TIMEOUT,
  RESTORE_STATE,
  fetchEventsByFilters,
  fetchEventsByPeriode,
  setSelection,
  setDate,
  setTitle,
  fetchEvent,
  fetchObjetLie,
  filterEvent
};
