import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import menusJson from '../conf/menu.json';
import BackButton from './back_button';
import territoireJson from '../conf/territoire.json';
const logoMenuHeader = '/assets/logo/logoMenuHeader.png';
const logoMenuFooter = '/assets/logo/logoMenuFooter.png';

export const SELECTION_HOME = "accueil";

export default class TopMenu extends Component {
  static contextTypes = {
    router: PropTypes.object
  }
  constructor(props, context) {
    super(props, context);
    let menus = [];
    Object.keys(menusJson).forEach(function(key) {
      menus.push(menusJson[key]);
    });
    this.state = { menus: menus[0] };
  }
  renderMenu = (menu) =>{
    return (
      <Nav.Item key={menu.position}>
          <Nav.Link href={`/${menu.path}/aujourdhui/liste`} eventkey={menu.position}>
              <FormattedMessage id={`menu.${menu.position}`}/>
          </Nav.Link>
        </Nav.Item>
    );
  }

  renderTerritoire = (territoire) =>{
    return (
      <p className={territoire.className} key={territoire.title}>{territoire.name}</p>
    );
  }

  render = () =>{
    var arr = [];
    Object.keys(territoireJson).forEach(function(key) {
      arr.push(territoireJson[key]);
    });
    return (
      <div className="top-menu">
        <Navbar inverse="1" collapseOnSelect className="navbar-inverse">
          <div className="navbar-header">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" >
              <span className="icon-bar">&nbsp;</span>
              <span className="icon-bar">&nbsp;</span>
              <span className="icon-bar">&nbsp;</span>
            </Navbar.Toggle>
            {this.props.backButton && this.props.backButton === true && <BackButton back = {this.props.back} />}
            <Navbar.Brand eventkey={0}>
              <Link to="/accueil/aujourdhui/liste" name="accueil" className="lien accueil">
                <p className="sortir">sortir</p>
                <p className="accroche">en Méditerranée Porte des Maures</p>
                <img decoding="async" loading="lazy" className="logoHeader" alt="logo" src={logoMenuHeader}/>
              </Link>
            </Navbar.Brand>
        </div>
        <Navbar.Collapse id="responsive-navbar-nav">
          <div className="nav-header">
            <Navbar.Brand eventkey={0}>
              <Link to="/accueil/aujourdhui/liste" name="accueil" className="lien accueil">
                <p className="sortir">sortir</p>
                <p className="accroche">en Méditerranée Porte des Maures</p>
                <img decoding="async" loading="lazy" className="logoHeader" alt="logo" src={logoMenuHeader}/>
              </Link>
            </Navbar.Brand>
            <div className="villes">
              {arr[0].map(this.renderTerritoire)}
            </div>
          </div>
          <Nav>
            {this.state.menus.map(this.renderMenu)}
            <Nav.Item>
              <Nav.Link href="https://www.mpmtourisme.com/node/623">
                Label qualité
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/territoire">
                Territoire
              </Nav.Link> 
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/mentions-legales">
                Mentions légales
              </Nav.Link>  
            </Nav.Item>
          </Nav>
          <div className="nav-footer">
            <img decoding="async" loading="lazy" className="logoFooter" alt="logo footer" src={logoMenuFooter}/>
          </div>
        </Navbar.Collapse>
      </Navbar>
    </div>
    );
  }
}
export { TopMenu };
TopMenu.contextTypes = {
  intl: PropTypes.object.isRequired,
};
