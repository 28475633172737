import menusJson from './conf/menu.json';
import EventList from './components/event_list';
import EventMap from './components/event_map';
import EventDetail from './components/event_detail';
// const normalizedMenuName = [''];
const tabs = ['aujourdhui', 'semaine', 'weekend', 'date'];
const displays = [
    {
        uri: 'liste',
        component: EventList
    },
    {
        uri: 'carte',
        component: EventMap
    },
    {
        uri: 'detail',
        component: EventDetail
    }
];

//const routes = []; 
export function routes() {
    let menus = [];
      Object.keys(menusJson).forEach(function(key) {
          menus.push(menusJson[key]);
    });
    
    menus = menus[0].map((menu) => {
        return {selectionId: menu.selection, path: menu.path};
    });
    
    // console.log(displays, 'displays');
    menus = menus.map((menu) => {
       return tabs.map((tab) => {
           //console.log(tab, 'tab');
           return displays.map((display) => {
               if(display.uri === 'detail') {
                //   console.log(display.component, 'display.component');
                  return {path: '/' + menu.path + '/' + tab + '/' + display.uri + '/:id', component: display.component, key: menu.selectionId + '_' + tab + '_' + display.uri};
               }
            //   console.log(display.component, 'display.component');
               return {path: '/' + menu.path + '/' + tab + '/' + display.uri, component: display.component, key: menu.selectionId + '_' + tab + '_' + display.uri};
           });   
       }).concatAll(); 
    }).concatAll();
    return menus;
}

Array.prototype.concatAll = function() {
	var results = [];
	this.forEach(function(subArray) {
		results.push.apply(results, subArray);
	});

	return results;
};

