import React, { Component } from 'react';

export default class BackButton extends Component {
  constructor(props) {
    super(props);
  }
  render = () =>{
    return (
      <button
        className="button icon-left backLink"
        onClick={this.props.back}>
          Back
      </button>
    );
  }
}
