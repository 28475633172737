import React, { Component } from 'react';
import ReactLoading from 'react-loading';

import { SELECTION_HOME } from './top_menu';
//TODOPROD
const url = "assets/img/splashscreen_pwa.jpg";


export default class SplashScreen extends Component {
  componentDidMount() {
    const url = `/${SELECTION_HOME}/aujourdhui/liste`;
    setTimeout(function() {
      this.setState({ hide: "hidden" });
      this.props.history.push(url);
    }.bind(this), 1500);
  }

  constructor(props) {
    super(props);
    this.state = { hide: '' };
  }
  render() {
    return (
    <div className={`{this.state.hide} body`} >
      <div className="title">
          <img decoding="async" loading="lazy" className="logo" alt="logo" src={url}/>
        </div>
        <div className="loadingDiv">
          <ReactLoading className="spinner" type="spinningBubbles" color="#B59C1E"  width="75" />
      </div>
    </div>
    );
  }
}
