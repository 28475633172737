import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { fetchEventsByPeriode } from '../actions/index';
import moment from 'moment';
import { SELECTION_HOME } from './top_menu';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { ws_date } from '../utils/ws_utils_mpma';
export const TODAY = "menu.today";
export const WEEK = "menu.week";
export const WEEKEND = "menu.weekend";
export const PICKDATE = "menu.date";
//import history from './history';
var activeDiv = '';


export default class TopBar extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    componentDidUpdate = () =>{
        switch (activeDiv) {
            case TODAY:
                this.refs.scroll.scrollLeft = '0';
                break;
            case WEEK:
                this.refs.scroll.scrollLeft = '100';
                break;
            case WEEKEND:
                this.refs.scroll.scrollLeft = '255';
                break;
            case PICKDATE:
                this.refs.scroll.scrollLeft = '350';
                break;
            default:
                this.refs.scroll.scrollLeft = '0';
                break;
        }
    }
    constructor(props) {
        super(props);
        this.state = { tab: '' };
    }
    today = (event) =>{
        activeDiv = TODAY;
        const now = moment().format('YYYY-MM-DD');
        const url = `/${this.props.selectionId}/aujourdhui/liste`;
        fetchEventsByPeriode(now, now, this.props.selectionId);
        this.props.go(url);
    }
    weekend = (event) =>{
        activeDiv = WEEKEND;
        this.setState({ tab: WEEKEND });
        const start = moment().add(1, 'weeks').startOf('isoWeek').subtract(2, "days").format('YYYY-MM-DD');
        const end = moment().add(1, 'weeks').startOf('isoWeek').subtract(1, "days").format('YYYY-MM-DD');
        const url = `/${this.props.selectionId}/weekend/liste`;
        fetchEventsByPeriode(start, end, this.props.selectionId);
        this.props.go(url);
    }
    week = (event) =>{
        activeDiv = WEEK;
        this.setState({ tab: WEEK });
        const start = moment(new Date()).format('YYYY-MM-DD');
        var end = moment().add(6, 'days').format('YYYY-MM-DD');
        const url = `/${this.props.selectionId}/semaine/liste`;
        fetchEventsByPeriode(start, end, this.props.selectionId);
        this.props.go(url);
    }
    pickDate = (event) =>{
        activeDiv = PICKDATE;
        this.setState({ tab: PICKDATE });
        const url = `/${this.props.selectionId}/date/liste`;
        this.props.go(url);
    }
    getClasses = (menuId, active) =>{
        let classes = [];
        classes.push('list-group-item');
        if (menuId == active) {
            classes.push('active');
        }
        return classes.join(" ");
    }
    render = () =>{
        const { active } = ws_date(this.props.tab);
        return (
            <div className="top-bar">
                <ul className="list-group nav nav-tabs" ref="scroll">
                    <li className={this.getClasses(TODAY, active)} onClick={this.today}><FormattedMessage id={TODAY}/></li>
                    <li className={this.getClasses(WEEK, active)} onClick={this.week}><FormattedMessage id={WEEK}/></li>
                    <li className={this.getClasses(WEEKEND, active)} onClick={this.weekend}><FormattedMessage id={WEEKEND}/></li>
                    <li className={this.getClasses(PICKDATE, active)} onClick={this.pickDate}><FormattedMessage id={PICKDATE}/></li>
                </ul>
            </div>
        );
    }
}

// function mapDispatchToProps(dispatch) {
//     return bindActionCreators({ fetchEventsByPeriode }, dispatch);
// }

// function mapStateToProps({ events }) {
//     return { events };
// }

// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopBar));
