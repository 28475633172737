import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ReactLoading from 'react-loading';
import _ from 'lodash';
import moment from 'moment';
import { ws_date, ws_parseLocation } from '../utils/ws_utils_mpma';
import { bindActionCreators } from 'redux';
import { fetchEventsByPeriode } from '../actions/index';
import territoireJson from '../conf/territoire.json';
import FilterBar from './filterbar';
import { SELECTION_HOME } from './top_menu';
import Footer from './footer';
import { default as TopMenu } from './top_menu';
import { default as TopBar } from './top_bar';
const defaultImage = 'https://sortir.mpmtourisme.com/assets/img/defaultImage.svg';
const emptyImage = 'https://sortir.mpmtourisme.com/assets/img/emptyImage.svg';
export const DISPLAY_LIST = 'liste';
export const DISPLAY_MAP = 'carte';

class EventList extends Component {
  constructor(props) {
    super(props);
    let territoires = [];
    Object.keys(territoireJson).forEach(function(key) {
      territoires.push(territoireJson[key]);
    });
    this.state = {
      territoires: territoires[0],
      start:0,
      count:200,
    };
  }
  componentDidMount = () =>{
    const { selection, tab } = ws_parseLocation(this.props.match.path);
    const { begin, end } = ws_date(tab);
    if (tab != 'date') {
      this.props.fetchEventsByPeriode(begin, end, selection, this.state.count);
    }
    else {
      var date = this.props.events.date || moment();
      this.props.fetchEventsByPeriode(moment(date).format('YYYY-MM-DD'), moment(date).add(365, 'days').format('YYYY-MM-DD'), selection, this.state.count);
    }
  }
  onBack = () =>{
    this.props.history.goBack();
  }
  appendEvents = (event) => {
    this.setState({ start: this.state.start + this.state.count });
    const { selection, tab } = ws_parseLocation(this.props.match.path);
    const { begin, end } = ws_date(tab);
    if (tab != 'date') {
      this.props.fetchEventsByPeriode(begin, end, selection, this.state.count, this.state.start+this.state.count, true);
    }
    else {
      var date = this.props.events.date || moment();
      this.props.fetchEventsByPeriode(moment(date).format('YYYY-MM-DD'), moment(date).add(365, 'days').format('YYYY-MM-DD'), selection, this.state.count, this.state.start+this.state.count, true);
    }
  }
  renderCity = (city) =>{
    var knownCity = [];
    knownCity = this.state.territoires.filter(function(territoire) {
      return territoire.title == city;
    });
    if (!_.isEmpty(knownCity)) {
      return knownCity.map(territoire => (<p className={territoire.className}>{territoire.name}</p>));
    }
    return (
      <div>{city}</div>
    );
  }
  renderEvent = (events, date) => {
    const libelle = 'libelleFr'; // + require('browser-locale')().capitalize();
    return _.map(events, event => {
      const private_data = (event.donneesPrivees) ? event.donneesPrivees: '';
      const TitreAgendaMpmPwa = (private_data) ? private_data.find((e) => e.nomTechnique == '1206_TitreAgendaMpmPwa') : '';
      const private_title = (TitreAgendaMpmPwa) ? TitreAgendaMpmPwa.descriptif[libelle]: '';
      const nom = private_title ? private_title : event.nom[libelle];
      const ville = event.localisation.adresse.commune.nom;
      var illustrations = event.illustrations;
      var illustration = defaultImage;
      var alt = event.nom[libelle];
      
      
      if (Array.isArray(illustrations) && illustrations.length > 0) {
        const firstIllustration = illustrations[0].traductionFichiers;
        if (Array.isArray(firstIllustration) && firstIllustration.length > 0) {
          if (firstIllustration[0].fileName) {
            alt = firstIllustration[0].fileName;
          }
          if (firstIllustration[0].urlDiaporama) {
            illustration = encodeURI(firstIllustration[0].urlDiaporama.replace('http:', 'https:'));
          }
        }
      }
      const { selection, tab } = ws_parseLocation(this.props.match.path);
      const url = `/${selection}/${tab}/detail/${event.id}`;
      return (
        <div className="col-12 col-sm-6 col-lg-4 col-xl-3 teaser" key={event.id}>
          <Link className="event-link" name={nom} aria-label={nom} to={url} key="${event.id}link">
              <img width="100%" decoding="async" loading="lazy" alt={alt} src={`https://images.weserv.nl/?w=373&h=226&fit=cover&l=9&il&output=jpg&q=75&url=${illustration}`}  key="${event.id}img"/>
          </Link>
          <Link className="event-link-2" name={nom + "2"} aria-label={nom + "2"} to={url} key="${event.id}link2">
              {event.informationsFeteEtManifestation.categories &&
                  <div className="categorie" key="${event.id}categorie">
                      {this.renderCategorie(event.informationsFeteEtManifestation.categories[0])}
                  </div>
              }
              {event.informationsFeteEtManifestation.themes &&
                  <div className="theme" key="${event.id}theme">
                      {this.renderTheme(event.informationsFeteEtManifestation.themes)}
                  </div>
              }
              <p className="description">{nom}</p>
              <p className="ville">{this.renderCity(ville)}</p>
          </Link>
      </div>
      );
    });
  }
  renderCategorie =(category) =>{
    const libelle = 'libelleFr'; // + local.capitalize();
    if (!category) {
      return <div className="noCategory"></div>;
    }
    var label = category[libelle];
    if (label === "Visite guidée et/ou commentée") {
      label = "Visite";
    }
    return (
      <p>{label}</p>
    );
  }
  renderTheme =(themes) =>{
    const libelle = 'libelleFr'; // + local.capitalize();
    if (!themes) {
      return <div className="noTheme"></div>;
    }
    var vin = false;
    themes.forEach((theme) => {
      if (theme[libelle] === "Oenologie") {
        vin = true;
      }      
    });
    if (vin === true){
      return (
        <p>Autour du vin</p>
      );
    }
  }
  renderDay = (date, events) =>{
    moment.locale("fr");
    var dt = moment(date, "YYYY-MM-DD");
    var dayName = dt.format("dddd");
    var dayNum = dt.format("DD");
    var mounthName = dt.format("MMMM");
    //display day bar and events for this day
    return (
      <div className="item-day" key={date}>
                <h3 className="event-date" key="${date}title"> {dayName} {dayNum} {mounthName}</h3>
                <div className="row" key="${date}row">
                    {this.renderEvent(events, date)}
                </div>
            </div>
    );

  }
  render = () =>{
    let isEmpty = true;
    let output = [];
    if (!_.isEmpty(this.props.events.currentState)) {
      for (let key in this.props.events.currentState) {
        let value = this.props.events.currentState[key];
        output.push(this.renderDay(key, value));
        isEmpty = false;
      }
    }
    else {}
    if (this.props.events[0]) {
      return (
        <div className="items-day">
                coucou
                </div>
      );
    }
    else {
      const { selection, tab } = ws_parseLocation(this.props.match.path);
      const { begin, end } = ws_date(tab);
      return (
        <div className="page-container">
                
                    <header>
                        <TopMenu 
                            go={ url => this.props.history.push(url) } 
                            back = {this.onBack}
                        />
                        <TopBar 
                            tab={tab} 
                            selectionId={selection} 
                            go={ url => this.props.history.push(url) } 
                            
                        />
                        {this.props.events.title &&
                            <h1 className="eventTittle">{this.props.events.title}</h1>
                        }
                        <FilterBar 
                            display={DISPLAY_LIST}
                            selectionId={selection}
                            tab={tab}
                        />
                    </header>
                    <main>
                    <div>
                            {this.props.events.loading === true &&
                                <div className="loadingDiv">
                                    <ReactLoading className="spinner" type="spinningBubbles" color="#B59C1E"  width={75} />
                                </div>
                            }
                            
                            {this.props.events.apidown === true &&
                            <div class="network-error">
                                <p><FormattedMessage id="error.apidaedown.0"/></p> 
                                <p><FormattedMessage id="error.apidaedown.1"/></p>
                            </div>
                            }
                            
                            
                            {this.props.events.currentState &&
                                <div className="items-day">
                                    {output}
                                </div>
                            }
                            {this.props.events.reloading === true &&
                                <div className="loadingDiv">
                                    <ReactLoading className="spinner" type="spinningBubbles" color="#B59C1E"  width={75} />
                                </div>
                            }
                            {this.props.events.hasMore &&
                            <button className="loadMoreEvent" onClick={this.appendEvents}><FormattedMessage id="event.more"/></button>
                            }
                            {isEmpty &&
                                <div className="item-day-null" key="null">
                                    <img decoding="async" loading="lazy" className="emptyImage" alt="image vide" width="512" src={emptyImage} />
                                    <p className="emptyText"><FormattedMessage id="noresult"/></p>
                                </div>    
                            }
                    </div>
                    </main>
                    <Footer 
                        islisting={1} 
                        tab={tab} 
                        selection={selection}
                        isEmpty={isEmpty}
                    />
                </div>
      );
    }

  }
}

// function checkDate(inputDate) {
//     let outputDate = {}; 
//     if(!inputDate) {
//             outputDate = moment(new Date()).format('YYYY-MM-DD');
//         } else {
//             outputDate   = moment(inputDate, 'YYYY-MM-DD', true);
//             var isValid = outputDate.isValid();
//             if(!isValid) {
//                 outputDate = moment(new Date()).format('YYYY-MM-DD');
//             }
//         }
//     return outputDate;
// }

// renderNull = () => {
  //   return (
  //     <div className="item-day-null" key="null">
  //               <img decoding="async" loading="lazy" className="emptyImage" alt="image vide" width="512px" src={emptyImage} />
  //               <p className="emptyText"><FormattedMessage id="noresult"/></p>
  //           </div>
  //   );
  // }

function mapStateToProps({ events }) {
  return { events };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchEventsByPeriode }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EventList);
