import React, {Component} from 'react';
import { Link, MemoryRouter } from 'react-router-dom';
import TopMenu from '../components/top_menu';


export default class MentionLegal extends Component {
 

     
    constructor(props) {
        super(props);
    }
    onBack = () =>{
        this.props.history.goBack();
    }
    render = () =>{
        return (
            <div className="page mentionsLegales">
                <TopMenu go={ url => this.props.history.push(url) } back = {this.onBack}/>
                <div className="mentionsLegales">
                    <h1>Mentions légales</h1>
                    <h2>Propriétaire de l’application et éditeur</h2>
                        <p>L’application Sortir en Méditerranée Porte des Maures est la propriété de l’office de tourisme intercommunal de Cuers, Collobrières, Pierrefeu du Var et La Londe Les Maures :</p>
        
                    <address>
                        <p><strong>Office de tourisme intercommunal Méditerranée Porte des Maures</strong></p>
                        <span className="adr">
                            <p className="street-address">Port Miramar - 60, boulevard du front de mer</p>
                            <p className="locality-postal-code-group">
                                <span className="postal-code">83250</span> <span className="locality">La Londe-les-Maures</span> <span className="country-name sr-only">France</span>
                            </p>
                        </span>
        
                        <p className="telephone"><a href="tel:+33494015310" title="Appeler maintenant" className="tel"></a>+33 4 94 01 53 10</p>
        
                        <p className="email"><a href="mailto:bienvenue@mpmtourisme.com?subject=%5BSortir en MPM%5D%20" className="email">bienvenue@mpmtourisme.com</a></p>
                    </address>
        
                    <h2>Directrices de la publication</h2>
        
                    <p>Véronique NERAND, Valérie COLLET et Lucie ALEONARD</p>
        
                    <h2>Création graphique</h2>
                    <p>La création graphique a été réalisée par l’<a href="https://www.websenso.com" target="_blank" title="Agence WebSenso - Webdesign personnalisé">agence WebSenso</a> sur base de la charte graphique de l’intercommunalité créée par <a href="http://www.autrementdit.fr" title="Agence de Communication Print Web Event" target="_blank">Autrement dit communication</a></p>
        
                    <h2>Développement technique</h2>
                    <p>Cette application Progress Web Application (PWA) a été réalisée par l'<a href="https://www.websenso.com" target="_blank" title="Agence Web Senso - Développement d'application PWA connectée à Apidaé">agence WebSenso</a> sur la technologie React JS et via l'API Apidaé (SIT touristique), ainsi que le CMS Drupal en headless.</p>
        
                    <h2>Maintenance applicative</h2>
                    <p>Afin d’assurer une bonne continuité de service, <a href="https://www.websenso.com/prestations/maintenance" target="_blank" title="Agence WebSenso - Maintenance d'application PWA">maintenance applicative et corrective</a> WebSenso opère la maintenance applicative et corrective de l’application PWA.</p>
    
                    <h2>Hébergement</h2>
                    <p>Ce service est hébergé physiquement en France dans un centre de données de la société OVH.com en France.</p>
                    <h2>Propriété intellectuelle</h2>
                    <p>L’office de Tourisme intercommunal Méditerranée Porte des Maures est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles dans l’application, notamment les textes, images, graphismes, logos, etc. Alors avant de copier, adapter, citer ce contenu, … Veuillez obtenir une autorisation écrite au préalable.</p>
                    
                    <h2>Liens hypertextes</h2>
                    <p>Lors de votre navigation, vous découvrirez plusieurs liens hypertextes vers d'autres sites web. L’office de tourisme intercommunal Méditerranée Porte des Maures n’a pas la possibilité de vérifier le contenu de ces sites et n’assumera en conséquence aucune responsabilité de ce fait.</p>
                    
                    <h2>Cookies</h2>
                    <p>La navigation sur ce site provoque l’installation de p'tits fichiers, nommés <a href="https://fr.wikipedia.org/wiki/Cookie_(informatique)" target="_blank" rel="nofollow noopener external">cookies</a>, sur le navigateur de l’utilisateur. Ils sont gentils et ne prennent pas de place ;-). Par contre, le refus d’installation d’un cookie peut bloquer techniquement l’accès à certains services et réduire notre capacité à améliorer l'expérience de navigation via des mesures d'audience. Ci-dessous la liste des cookies utilisés.</p>
                    
                    <h2>Gestion des données personnelles</h2>
                    <p>En surfant sur ce site, des informations peuvent êtres recueillies : provenances, fournisseur d'accès, adresse IP, etc. L’office de Tourisme intercommunal Méditerranée Porte des Maures collecte des informations personnelles pour le besoin de certains services proposés par ce site, voir ci dessus. Le droit d’accès, de rectification et d’opposition aux données personnelles concernant les visiteurs, s'effectuent par demande écrite à l'adresse ci-dessus.</p>
                    
                    <h2>Droit applicable et attribution de juridiction</h2>
                    <p>Tout litige en relation avec l’utilisation de ce site est soumis au droit français et dans la langue de Molière. Cocorico !</p>
    
                </div>
            </div>
        );
    }
    
}