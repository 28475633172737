import moment from 'moment';
import {TODAY, WEEK, WEEKEND, PICKDATE} from "../components/top_bar";
import menusJson from '../conf/menu.json';
import i18nJson from '../conf/i18n.json';
import _ from 'lodash';

export function ws_date(tab) {
        let wsDate = {};
        var begin;
        var end;
        var active;
        switch(tab) {
            case 'aujourdhui':
                begin = moment().format('YYYY-MM-DD');
                end = moment().format('YYYY-MM-DD');
                active = TODAY;
                break;
            case 'semaine':
                 begin = moment(new Date()).format('YYYY-MM-DD');
                end = moment().add(7, 'days').format('YYYY-MM-DD');
                active = WEEK;
                break;
            case 'weekend':
                begin = moment().add(1, 'weeks').startOf('isoWeek').subtract(2, "days").format('YYYY-MM-DD');
                end = moment().add(1, 'weeks').startOf('isoWeek').subtract(1, "days").format('YYYY-MM-DD');
                active = WEEKEND;
                break;
            default:
                begin = moment().format('YYYY-MM-DD');
                end = moment().format('YYYY-MM-DD');
                active = PICKDATE;
                break;
        }
        wsDate.begin = begin;
        wsDate.end = end;
        wsDate.active = active
        return wsDate;
}

export function ws_selectionByPath(path) {
    let menus = [];
    Object.keys(menusJson).forEach(function(key) {
          menus.push(menusJson[key]);
    });
    
    const mapPath = _.mapKeys(menus[0], "path");
    return mapPath[path].selection;
}

export function ws_selectionById(id) {
    let menus = [];
    Object.keys(menusJson).forEach(function(key) {
          menus.push(menusJson[key]);
    });
    
    const mapPath = _.mapKeys(menus[0], "selection");
    return mapPath[id].path;
}



export function ws_parseLocation(location) {
    var matches = location.match(/\/(.*?)\//);
    let wsLocation = {};
    if (matches) {
        var submatch = matches[0];
        wsLocation.selection = submatch.replaceAll("\/", "");
        location = location.replace("/" + wsLocation.selection, "");
        matches = location.match(/\/(.*?)\//);
        submatch = matches[0];
        wsLocation.tab = submatch.replaceAll("\/", "");
    }
    return wsLocation; 
}

String.prototype.replaceAll = function(search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
};

 


