import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import PhoneNumber from 'react-phone-number';
import Mailto from 'react-protected-mailto';
import Slider from "react-slick";
import ShowMoreText from 'react-show-more-text';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { fetchEvent } from '../actions/index';
import { ws_date, ws_parseLocation } from '../utils/ws_utils_mpma';
import Meta from './meta';
import TopMenu from './top_menu';
import TopBar from './top_bar';
import ObjetLies from './objet_lie';
import CustomMap from './custom_map';
import territoireJson from '../conf/territoire.json';
import feriesJson from '../conf/feries.json';
import Footer from './footer';
const FB_APP_ID = 431930443947497;
const local = "fr";
const defaultImage = 'https://sortir.mpmtourisme.com/assets/img/defaultImage.svg';

class EventDetail extends Component {
  constructor(props) {
    super(props);
    let territoires = [];
    Object.keys(territoireJson).forEach(function(key) {
      territoires.push(territoireJson[key]);
    });
    this.state = {
      lat: 51.505,
      lng: -0.09,
      zoom: 13,
      territoires: territoires[0],
    };
  }
  componentDidMount = () => {
    const { tab } = ws_parseLocation(this.props.match.path);
    const { begin } = ws_date(tab);
    const { id } = this.props.match.params;
    this.props.fetchEvent(begin, id);
  }
  onBack = () => {
    this.props.history.goBack();
  }
  renderCategory = (category) => {
    const libelle = 'libelleFr'; // + local.capitalize();
    if (!category) {
      return <div className="noCategory"></div>;
    }
    var label = category[libelle];
    if (label === "Visite guidée et/ou commentée") {
      label = "Visite";
    }
    return (
      <div className="category" key={category[libelle]}>{label}</div>
    );
  }
  renderTheme = (theme) => {
    const libelle = 'libelleFr'; // + local.capitalize();
    if (!theme) {
      return <div className="noTheme"></div>;
    }
    var label = theme[libelle];
    if (label === "Oenologie") {
      label = "Autour du vin";
      return (
        <div className="theme" key={theme[libelle]}>{label}</div>
      );
    }else{
      return <div className="noTheme"></div>;
    }
  }
  renderIllustrations = (illustration, index) => {

    var altIllustration = "";
    var urlIllustration = defaultImage;
    console.log(illustration);
    if (illustration.traductionFichiers[0].fileName) {
      altIllustration = illustration.traductionFichiers[0].fileName;
    }
    if (illustration.traductionFichiers[0].urlDiaporama) {
      urlIllustration = encodeURI(illustration.traductionFichiers[0].urlDiaporama.replace('http:', 'https:'));
    }
    return (
      <div key={index}>
          <img width="100%" decoding="async" loading="lazy" alt={altIllustration} src={`https://images.weserv.nl/?w=450&l=9&il&output=jpg&q=75&url=${urlIllustration}`} />
      </div>
    );
  }
  renderPhone = (phone) => {
    return (
      <div key="phone">
          <PhoneNumber className="picto-tel" number={phone.coordonnees[local]} isLinked={true} />
      </div>
    );
  }
  renderEmail = (mail) => {
    return (
      <div key="mail">
          <Mailto className="picto-mail" email={mail.coordonnees[local]}>Email</Mailto>
      </div>
    );
  }
  renderSiteWeb = (siteWeb) => {
    return (
      <div key={`website.${siteWeb.identifiant}`}>
          <a href={siteWeb.coordonnees.fr} target="_blank"className="picto-siteWeb" name={siteWeb.coordonnees[local]}>{siteWeb.coordonnees[local]}</a>
      </div>
    );
  }
  renderFacebook = (facebook) => {
    return (
      <div key="fb">
          <a href={facebook.coordonnees.fr} target="_blank" className="picto-fb" name={facebook.coordonnees[local]}>{facebook.coordonnees[local]}</a>
      </div>
    );
  }
  renderFlag = (langue) => {
    const { id } = langue;
    const libelle = langue['libelleFr'];
    return (
      <span className={`flag-${id}`} key={id}>{libelle}</span>
    );
  }
  renderEventClass = (city) => {
    var knownCity = [];
    knownCity = this.state.territoires.filter(function(territoire) {
      return territoire.title == city;
    });
    if (!_.isEmpty(knownCity)) {
      return knownCity.map((territoire, index) => ('event ' + territoire.className));
    }
    return (
      event
    );
  }
  renderFacebookShareLinks = () => {
    let url = window.location;
    return (
      <a href={`https://www.facebook.com/sharer/sharer.php?${url}`} target="_blank" class="share-fb">
          <span class="fb-icon"></span> partager
      </a>
    );
  }

  renderSmsShareLinks = (nom) => {
    let url = window.location;
    return (
      <a href={`sms://+?body=${nom}%20%3A%0D%0A${url}`} class="share-sms">
          <span class="fb-icon"></span> partager
      </a>
    );
  }
  renderMailShareLinks = (nom) => {
    let url = window.location;
    return (
      <a href={`mailto:?subject=${nom}&body=${nom}%20%3A%0D%0A${url}`} class="share-mail">
          <span class="mail-icon"></span> partager
      </a>
    );
  }
  
  renderApiShareLinks = (nom) => {
    
    const handleShareButton = () => {
      // Check if navigator.share is supported by the browser
      if (navigator.share) {
        console.log("Congrats! Your browser supports Web Share API");
        navigator
          .share({
            url: window.location
          })
          .then(() => {
            console.log("Sharing successfull");
          })
          .catch(() => {
            console.log("Sharing failed");
          });
      } else {
        console.log("Sorry! Your browser does not support Web Share API");
      }
    };
  

    return (
      <a href="#" class="share-api" onClick={handleShareButton}>
          <span class="fb-icon"></span> partager
      </a>
    );
  }
  


  renderMultimedias = (multimedia, index) => {
    var local = local;
    return (
      <span key={index}>{
            multimedia.traductionFichiers.filter(
            (traduction) => traduction.local == local).map((traduction, index) => 
            <a className="btn icon-multimedia" name={traduction.url} href={traduction.url} target={"_blank"}>
                {traduction.fileName}
            </a> 
            ) 
        }</span>
    );
  }
  getLinkedObjetsByType = () => {
    const { event } = this.props;
    var objetLiesByType = [];
    if (event.liens.liensObjetsTouristiquesTypes) {
      var objetsLies = event.liens.liensObjetsTouristiquesTypes;
      let types = [];
      types = objetsLies.filter((objetLie) => {
        return (objetLie.type == "ORGANISATEUR_PROGRAMME") || (objetLie.type == "PROGRAMME_ORGANISATEUR");
      }).map(function(linkedObj) {
        return linkedObj.type;
      }).filter(function(item, pos, self) {
        return self.indexOf(item) == pos;
      });
      //build list of id for each type
      types.forEach(function(type) {
        let eventByType = [];
        eventByType = objetsLies.filter(function(obj) {
          return obj.type == type;
        }).map((obj) => obj);
        objetLiesByType[type] = eventByType;
      });
    }
    return objetLiesByType;
  }
  renderObjetsLies = (objetLiesByType) => {
    let output = [];
    for (let type in objetLiesByType) {
      let objects = objetLiesByType[type];
      if (Array.isArray(objects)) {
        output.push(this.renderObjectsByType(type, objects));
      }
    }
    return output;
  }
  renderObjectsByType = (type, objects) => {
    var newType = '';
    switch (type) {
      case 'ORGANISATEUR_PROGRAMME':
        newType = this.context.intl.formatMessage({ id: "event.program", defaultMessage: "Program" }); //'Programme';
        break;
      case 'PROGRAMME_ORGANISATEUR':
        newType = this.context.intl.formatMessage({ id: "event.allprograms", defaultMessage: "All the program" });
        break;
      default:
        newType = type;
        break;
    }
    return (
      <div className="category objet-lie" key={type}>
          <div className="row">
              {this.renderObjet(objects,newType)}
          </div>
      </div>
    );
  }
  renderObjet = (events, type) => {
    const { selection, tab } = ws_parseLocation(this.props.match.path);
    return (
      <ObjetLies events={events} type={type} key="objets" renderCategory={this.renderCategory} renderTheme={this.renderTheme} selection={selection} tab={tab}/>
    );
  }
  renderTarifLine = (tarifs) => {
    let libelle = 'PAYANT';
    let message = '';
    // tarifs[0].tarifs.forEach((tarif, index) => {
    if (tarifs[0] && tarifs[0].tarifs && tarifs[0].tarifs[0]) {
      if ([1727, 1750, 4123, 1717, 172, 1754].includes(tarifs[0].tarifs[0].type.id)) {
        libelle = 'ADULTE';
        message = <div class="tarifs-simple"><a href="#tarifs-clair"><FormattedMessage id={'tarif.'+libelle}/> : {tarifs[0].tarifs[0].maximum}€</a></div>;
      }
      else if ([5431, 5239, 5250].includes(tarifs[0].tarifs[0].type.id)) {
        libelle = 'PLEIN';
        message = <div class="tarifs-simple"><a href="#tarifs-clair"><FormattedMessage id={'tarif.'+libelle}/> : {tarifs[0].tarifs[0].maximum}€</a></div>;
      }
      else if ([4108, 4112, 4123].includes(tarifs[0].tarifs[0].type.id)) {
        libelle = 'UNIQUE';
        message = <div class="tarifs-simple"><a href="#tarifs-clair"><FormattedMessage id={'tarif.'+libelle}/> : {tarifs[0].tarifs[0].maximum}€</a></div>;
      }
      else {
        message = <div class="tarifs-simple"><a href="#tarifs-clair"><FormattedMessage id={'tarif.'+libelle}/></a></div>;
      }
    }
    else {
      message = <div class="tarifs-simple"><a href="#tarifs-clair"><FormattedMessage id={'tarif.'+libelle}/></a></div>;
    }
    // });
    return message;
  }
  renderHeureLine = (dates) => {
    var ligneHeure = ``;
    moment.locale('fr');
    var dateAjd = moment(new Date());
    let cpt = 0;
    dates.forEach(function(ouverture) {
      let dateFin = moment(ouverture.dateFin);
      if (dateFin.format('YYYY-MM-DD') >= dateAjd.format('YYYY-MM-DD') & cpt == 0) {
        cpt++;
        let dateDebut = moment(ouverture.dateDebut);
        if (dateDebut.format('YYYY-MM-DD') == dateFin.format('YYYY-MM-DD')) {
          if (ouverture.horaireOuverture && !ouverture.horaireFermeture) {
            let heureOuverture = moment(ouverture.horaireOuverture, 'HH:mm:ss');
            ligneHeure += heureOuverture.format("HH[h]mm");
          }
          else if (ouverture.horaireOuverture && ouverture.horaireFermeture) {
            let heureOuverture = moment(ouverture.horaireOuverture, 'HH:mm:ss');
            ligneHeure += heureOuverture.format("HH[h]mm");
            let heureFermeture = moment(ouverture.horaireFermeture, 'HH:mm:ss');
            ligneHeure += ` - ` + heureFermeture.format("HH[h]mm");
          }
        }
        else {
          if (ouverture.horaireOuverture && !ouverture.horaireFermeture) {
            let heureOuverture = moment(ouverture.horaireOuverture, 'HH:mm:ss');
            ligneHeure += heureOuverture.format("HH[h]mm");
          }
          else if (ouverture.horaireOuverture && ouverture.horaireFermeture) {
            let heureOuverture = moment(ouverture.horaireOuverture, 'HH:mm:ss');
            ligneHeure += heureOuverture.format("HH[h]mm");
            let heureFermeture = moment(ouverture.horaireFermeture, 'HH:mm:ss');
            ligneHeure += ` - ` + heureFermeture.format("HH[h]mm");
          }
        }
      }
    });
    return ligneHeure;
  }
  renderDateLine = (dates) => {
    let ligneDate = ``;
    moment.locale('fr');
    let dateAjd = moment(new Date());
    let cpt = 0;
    dates.forEach(function(ouverture) {
      let dateFin = moment(ouverture.dateFin);
      if (dateFin.format('YYYY-MM-DD') >= dateAjd.format('YYYY-MM-DD') & cpt == 0) {
        cpt++;
        let dateDebut = moment(ouverture.dateDebut);
        if (dateDebut.format('YYYY-MM-DD') == dateFin.format('YYYY-MM-DD')) {
          ligneDate += dateDebut.format('DD/MM');
        }
        else {
          if (dateDebut.format('YYYY-MM-DD') < dateAjd.format('YYYY-MM-DD')) {
            dateDebut = dateAjd;
          }
          ligneDate += `Du ` + dateDebut.format('DD/MM');
          ligneDate += ` au ` + dateFin.format('DD/MM');
        }
      }
    });
    return ligneDate;
  }
  renderDates = (dates) => {
    const { event } = this.props;
    const context = this.context;
    const libelle = 'libelleFr';
    var periodeOuverture = ``;
    moment.locale('fr');
    var dateAjd = moment(new Date());
    var currentYear = dateAjd.year();
    dates.forEach(function(ouverture) {
      if (ouverture.type != "OUVERTURE_TOUS_LES_JOURS") {
        let dateFin = moment(ouverture.dateFin);
        if (dateFin.format('YYYY-MM-DD') >= dateAjd.format('YYYY-MM-DD')) {
          let dateDebut = moment(ouverture.dateDebut);
          if (dateDebut.format('YYYY-MM-DD') == dateFin.format('YYYY-MM-DD')) {
            periodeOuverture += dateDebut.format('dddd Do MMMM  YYYY');
            if (ouverture.horaireOuverture && !ouverture.horaireFermeture) {
              let heureOuverture = moment(ouverture.horaireOuverture, 'HH:mm:ss');
              periodeOuverture += ` à ` + heureOuverture.format("HH[h]mm");
            }
            else if (ouverture.horaireOuverture && ouverture.horaireFermeture) {
              let heureOuverture = moment(ouverture.horaireOuverture, 'HH:mm:ss');
              periodeOuverture += ` de ` + heureOuverture.format("HH[h]mm");
              let heureFermeture = moment(ouverture.horaireFermeture, 'HH:mm:ss');
              periodeOuverture += ` à ` + heureFermeture.format("HH[h]mm");
            }
            periodeOuverture += `<br/>`;
          }
          else {
            if (dateDebut.format('YYYY-MM-DD') < dateAjd.format('YYYY-MM-DD')) {
              dateDebut = dateAjd;
              periodeOuverture += 'Jusqu\'';
            }
            else {
              periodeOuverture += `Du ` + dateDebut.format('Do MMMM  YYYY') + ' ';
            }
            periodeOuverture += `au ` + dateFin.format('Do MMMM  YYYY');

            if (ouverture.ouverturesJournalieres) {
              periodeOuverture += `, tous les `;
              ouverture.ouverturesJournalieres.forEach(function(ouverturesJournaliere) {
                periodeOuverture += '<br/>' + context.intl.formatMessage({ id: "jour." + ouverturesJournaliere.jour, defaultMessage: "day" }) + `s, `;
              });
            }
            else {
              periodeOuverture += `, tous les jours.`;
            }
            if (ouverture.horaireOuverture && !ouverture.horaireFermeture) {
              let heureOuverture = moment(ouverture.horaireOuverture, 'HH:mm:ss');
              periodeOuverture += ` à ` + heureOuverture.format("HH[h]mm");
            }
            else if (ouverture.horaireOuverture && ouverture.horaireFermeture) {
              let heureOuverture = moment(ouverture.horaireOuverture, 'HH:mm:ss');
              periodeOuverture += ` de ` + heureOuverture.format("HH[h]mm");
              let heureFermeture = moment(ouverture.horaireFermeture, 'HH:mm:ss');
              periodeOuverture += ` à ` + heureFermeture.format("HH[h]mm");
            }
            periodeOuverture += `<br/>`;
          }
          if (event.ouverture.fermeturesExceptionnelles) {
            event.ouverture.fermeturesExceptionnelles.forEach(function(fermeturesExceptionnelle) {
              let dateFermetureExceptionnelle = "";
              if (fermeturesExceptionnelle.dateSpeciale) {
                dateFermetureExceptionnelle = moment(feriesJson[fermeturesExceptionnelle.dateSpeciale][currentYear]);
              }
              else if (fermeturesExceptionnelle.dateFermeture) {
                dateFermetureExceptionnelle = moment(fermeturesExceptionnelle.dateFermeture);
              }
              if (dateFermetureExceptionnelle.format('YYYY-MM-DD') >= dateAjd.format('YYYY-MM-DD') && dateFermetureExceptionnelle.format('YYYY-MM-DD') <= dateFin.format('YYYY-MM-DD')) {
                periodeOuverture += ` sauf le ` + dateFermetureExceptionnelle.format('Do MMMM  YYYY');
              }
            });
            periodeOuverture += `<br/>`;
          }
          if (ouverture.ouverturesExceptionnelles) {
            ouverture.ouverturesExceptionnelles.forEach(function(ouvertureExceptionnelle) {
              let dateOuvertureExceptionnelle = moment(ouvertureExceptionnelle.dateOuverture);
              if (dateOuvertureExceptionnelle.format('YYYY-MM-DD') >= dateAjd.format('YYYY-MM-DD')) {
                periodeOuverture += `Date supplémentaire le ` + dateOuvertureExceptionnelle.format('Do MMMM  YYYY');
              }
            });
            periodeOuverture += `<br/>`;
          }
          if (ouverture.complementHoraire && ouverture.complementHoraire[libelle]) {
            periodeOuverture += ouverture.complementHoraire[libelle];
            periodeOuverture += `<br/>`;
          }
        }
      }
      else {
        periodeOuverture = event.ouverture.periodeEnClair.libelleFr.replace("\n", '<br/>').replace("\r\n", '<br/>');
      }
    });
    return periodeOuverture.replace("\n", '<br/>').replace("\r\n", '<br/>');
  }
  getRootUrl = () => {
    return window.location.origin ? window.location.origin + '/' : window.location.protocol + '/' + window.location.host + '/';
  }
  getBaseUrl = () => {
    var re = new RegExp(/^.*\//);
    return re.exec(window.location.href);
  }
  getCarousselClass = () => {
    if (!this.props.event.illustrations) {
      return "images noPIc";
    }
    if (this.props.event.illustrations.length == 1) {
      return "images singlePic";
    }
    return "images";
  }
  renderCity = (city) => {
    var knownCity = [];
    knownCity = this.state.territoires.filter(function(territoire) {
      return territoire.title == city;
    });
    if (!_.isEmpty(knownCity)) {
      return knownCity.map(territoire => (<p className={territoire.className}>{territoire.name}</p>));
    }
    return (
      <div>{city}</div>
    );
  }
  render = () => {
    const { selection, tab } = ws_parseLocation(this.props.match.path);
    const libelle = 'libelleFr'; // + local.capitalize();
    const { event, loading, apidown } = this.props;
    if (loading === true) {
      return (
        <div className="page-container">
            <header>
                <TopMenu 
                  go={ url => this.props.history.push(url) } 
                  back = {this.onBack}
                />
                <TopBar 
                  tab={tab} 
                  selectionId={selection} 
                  go={ url => this.props.history.push(url) } 
                />
            </header>
            <main>
                <div className="page-detail">
                    <div className="loadingDiv">
                        <ReactLoading className="spinner" type="spinningBubbles" color="#B59C1E"  width={75} />
                    </div>
                </div>
            </main>
        </div>
      );
    }
    if (apidown === true) {
      return (
        <div className="page-container">
          <header>
              <TopMenu go={ url => this.props.history.push(url) } back = {this.onBack}/>
              
              <TopBar 
                  tab={tab} 
                  selectionId={selection} 
                  go={ url => this.props.history.push(url) } 
              />
          </header>
          <main>
              <div className="page-detail">
                  <div className="network-error">
                      <div className="network-error">
                          <p><FormattedMessage id="error.apidaedown.0"/></p> 
                          <p><FormattedMessage id="error.apidaedown.1"/></p>
                      </div>
                  </div>
              </div>
          </main>
      </div>
      );
    }

    if (!event.nom) {
      return (
        <div className="page-container">
          <header>
              <TopMenu go={ url => this.props.history.push(url) } back = {this.onBack}/>
              
              <TopBar 
                  tab={tab} 
                  selectionId={selection} 
                  go={ url => this.props.history.push(url) } 
                  
              />
          </header>
          <main>
              <div className="page-detail">
                  <div className="loadingDiv">
                      <ReactLoading className="spinner" type="spinningBubbles" color="#B59C1E"  width={75} />
                  </div>
              </div>
          </main>
      </div>
      );
    }
    var date = this.props.match.params.date;
    if (!date) {
      date = moment().format('YYYY-MM-DD');
    }
    const { coordinates } = event.localisation && event.localisation.geolocalisation && event.localisation.geolocalisation.geoJson ? event.localisation.geolocalisation.geoJson : false;
    const lat = coordinates ? coordinates[1] : false;
    const lon = coordinates ? coordinates[0] : false;
    const position = lat && lon ? [lat, lon] : false;
    let markers = [];
    let marker = {};
    marker.key = event.id;
    marker.position = position;
    marker.target = '_self';
    marker.class = 'nolink';
    marker.selectionId = selection;
    marker.tab = tab;
    marker.children = event.nom[libelle];
    marker.date = date;
    markers.push(marker);

    var objetLiesByType = this.getLinkedObjetsByType();
    if (!event.nom[libelle]) {
      return <div>Contenu non traduit</div>;
    }
    const reservation = (event.reservation) ? event.reservation : '';
    const organisme = (reservation.organismes) ? (reservation.organismes[0]) ? reservation.organismes[0] : '' : '';
    const moyensCommunication = (organisme.moyensCommunication) ? organisme.moyensCommunication : '';
    const telReservation = (moyensCommunication) ? moyensCommunication.find((e) => e.type.id == '201') : '';
    const telReservationData = (telReservation) ? telReservation.coordonnees.fr : '';
    const mailReservation = (moyensCommunication) ? moyensCommunication.find((e) => e.type.id == '204') : '';
    const mailReservationData = (mailReservation) ? mailReservation.coordonnees.fr : '';
    const urlReservation = (moyensCommunication) ? moyensCommunication.find((e) => e.type.id == '205') : '';
    const urlReservationData = (urlReservation) ? urlReservation.coordonnees.fr : '';

    const private_data = (event.donneesPrivees) ? event.donneesPrivees : '';
    const TitreAgendaMpmPwa = (private_data) ? private_data.find((e) => e.nomTechnique == '1206_TitreAgendaMpmPwa') : '';
    const private_title = (TitreAgendaMpmPwa) ? TitreAgendaMpmPwa.descriptif[libelle] : '';
    const title = private_title ? private_title : event.nom[libelle];
    const description = event.presentation.descriptifCourt[libelle];
    const nom = event.localisation.adresse.commune.nom;
    var image = (event.illustrations) ? event.illustrations[0].traductionFichiers[0].urlFiche : '';

    var re = new RegExp(/^.*\//);
    const url = re.exec(window.location.href)['input'];
    // const rootUrl = window.location.origin ? window.location.origin + '/' : window.location.protocol + '/' + window.location.host + '/';
    const carousselClasses = this.getCarousselClass();
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplaySpeed: 4000,
      autoplay: true,
      slidesToShow: 1
    };
    return (
      <div className="page-container">
        <header>
            <TopMenu go={ url => this.props.history.push(url) } back = {this.onBack} backButton={true}/>
            
            <TopBar 
                tab={tab} 
                selectionId={selection} 
                go={ url => this.props.history.push(url) } 
                
            />
        </header>    
        <main>  
            <div className="page-detail">
                <div className={this.renderEventClass(nom)}>
                    <h1 className="titre">{title}</h1>
                    {event.informationsFeteEtManifestation && event.informationsFeteEtManifestation.categories &&
                        <h2 className="category">{event.informationsFeteEtManifestation.categories.map(this.renderCategory)}</h2>
                    }
                    {event.informationsFeteEtManifestation && event.informationsFeteEtManifestation.themes &&
                        <h2 className="theme">{event.informationsFeteEtManifestation.themes.map(this.renderTheme)}</h2>
                    }
                    <div>
                  
                    {event.illustrations &&
                        <Slider {...settings} className={carousselClasses}>
                            {event.illustrations.map(this.renderIllustrations)}
                        </Slider>
                    }
                    </div>
                    <h3 className="ville">{nom}
                      {event.informationsFeteEtManifestation && event.informationsFeteEtManifestation.nomLieu && 
                        <span className="nomLieu">{event.informationsFeteEtManifestation.nomLieu}</span>
                      }
                    </h3>

                    <div className="titre-rapel">{title}</div>
                    <div className="infos-rapides">
                      {event.descriptionTarif.indicationTarif && event.descriptionTarif.indicationTarif != 'PAYANT' &&
                      <div className="info-rapide tarifs-simple">
                        <a href="#tarifs-clair">
                          <FormattedMessage id={'tarif.'+event.descriptionTarif.indicationTarif}/>
                        </a>
                      </div>
                      }
                      {event.descriptionTarif.periodes && event.descriptionTarif.indicationTarif == 'PAYANT' &&
                       <div className="info-rapide tarifs-simple">
                          {this.renderTarifLine(event.descriptionTarif.periodes)}
                       </div>
                       }
                      {event.ouverture.periodesOuvertures[0].horaireOuverture &&
                          <div className="info-rapide heures-simple">
                            <a href="#dates-clair">
                              {this.renderHeureLine(event.ouverture.periodesOuvertures)}
                            </a>
                          </div>
                      }
                      {event.ouverture.periodesOuvertures &&
                          <div className="info-rapide dates-simple">
                            <a href="#dates-clair">
                              {this.renderDateLine(event.ouverture.periodesOuvertures)}
                            </a>
                          </div>
                      }
                    </div>
                    
                    {event.informations.moyensCommunication &&
                        <div className="row com-container">
                            {event.informations.moyensCommunication.filter((moyen) => moyen.type.id == 201).map(this.renderPhone)}
                            {event.informations.moyensCommunication.filter((moyen) => moyen.type.id == 204).map(this.renderEmail)}
                            {event.informations.moyensCommunication.filter((moyen) => moyen.type.id == 205).map(this.renderSiteWeb)}
                            {event.informations.moyensCommunication.filter((moyen) => moyen.type.id == 207).map(this.renderFacebook)}
                            {(lat && lon) &&
                            <div><a className="picto-go" href={`https://www.openyourmap.link/?q=${lat},${lon}`} target="_blank" name="itineraire"> <FormattedMessage id="event.go"/></a></div>
                            }
                        </div>
                    }
                    {moyensCommunication &&
                        <div className="row reservation-container">
                          {(urlReservationData) ?
                            <a className="reservation-link" href={urlReservationData} target="_blank" name="reservation">Réserver</a>
                            : (mailReservationData) ?
                            <a className="reservation-link" href={`mailto:${mailReservationData}`} target="_blank" name="reservation">Réserver</a>
                            : (telReservationData) ?
                            <a className="reservation-link" href={`tel:${telReservationData}`} target="_blank" name="reservation">Réserver</a>
                            :
                            ''}
                        </div>
                    }
                    <div className="presentation">
                        <p className="desc short">{description}</p>
                        {event.presentation.descriptifDetaille && event.presentation.descriptifDetaille[libelle] &&
                            <ShowMoreText 
                                lines={1} 
                                onClick={this.props.onChange} 
                                more={this.context.intl.formatMessage({id: "event.moreinfo", defaultMessage:"More détails"})}
                                less={this.context.intl.formatMessage({id: "event.lessinfo", defaultMessage:"Less détails"})}
                                anchorclassName="desc long"
                            >
                            {event.presentation.descriptifDetaille[libelle].split('\n').map(this.renderLine)}
                            </ShowMoreText>
                        }
                    </div> 
                    
                    <div class="infos-clair">
                      {event.descriptionTarif.tarifsEnClair[libelle] &&
                        <div className="tarifs-clair" id="tarifs-clair">
                            <p className="header"><FormattedMessage id="event.tarif"/></p> 
                            <p className="desc">{event.descriptionTarif.tarifsEnClair[libelle].split(/\n|, /).map(this.renderLine)}</p>
                        </div>
                      }
                      {event.ouverture.periodesOuvertures &&
                          <div className="dates-clair" id="dates-clair">
                            <p className="header"><FormattedMessage id="event.ouverture"/></p> 
                            <p className="desc">{this.renderDates(event.ouverture.periodesOuvertures).split('<br/>').map(this.renderLine)}</p>
                          </div>
                      }
                      <div className="others-infos">
                        {event.prestations.languesParlees &&
                            <div className="lang">
                                <p className="header"><FormattedMessage id="event.language"/></p>
                                <p className="desc">{event.prestations.languesParlees.map(this.renderFlag)}</p>
                            </div>
                        }
                        {event.multimedias &&
                          <div className="multimedia">
                              <p className="header"><FormattedMessage id="event.multimedia"/></p> 
                              <p className="desc">{event.multimedias.map(this.renderMultimedias)}</p>
                          </div>
                        }
                      </div>
                    </div>
                    {(lat && lon) &&
                      <div className="laeflet">
                          <CustomMap center={position} zoom={this.state.zoom} markers={markers} key={event.id}/>
                      </div>
                    }
                    <div className="adresse">
                        <p className="rue1">{event.localisation.adresse.adresse1}</p>
                        {event.localisation.adresse.adresse2 &&
                        <p className="rue2">{event.localisation.adresse.adresse2}</p>
                        }
                        <p className="ville">{event.localisation.adresse.codePostal} {event.localisation.adresse.commune.nom}</p>
                        {event.informationsFeteEtManifestation && event.informationsFeteEtManifestation.nomLieu && 
                          <p className="nomLieu">{event.informationsFeteEtManifestation.nomLieu}</p>
                        }
                        {(lat && lon) &&
                          <a className="btn btn-info" href={`https://www.openyourmap.link/?q=${lat},${lon}`} target="_blank" name="itineraire"><FormattedMessage id="event.path"/></a>
                        }
                    </div>
                    
                    {objetLiesByType &&
                        <div className="objetslies">
                            {this.renderObjetsLies(objetLiesByType)}
                        </div>
                    }

                     <div class="share-links">

                        {this.renderApiShareLinks(title)}
                     </div> 
                     <div id="openGraph">
                        <Meta title={title} description={description} ogtitle={title} ogimage={image} ogurl={url} ogtype="article" ogtitle={title} ogdescription={description} ogimageurl={image} fbAppId={FB_APP_ID} />
                        
                    </div>
                </div>
            </div>
        </main>
        <Footer 
            islisting={0} 
            tab={tab}
        />
    </div>
    );
  }
  renderLine(line, i, arr) {
    const lines = <span key={i}>{line}</span>;
    if (i === arr.length - 1) {
      return lines;
    }
    else {
      return [lines, <br key={i + 'br'} />];
    }
  }
}

EventDetail.contextTypes = {
  intl: PropTypes.object.isRequired
};

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

function mapStateToProps({ events }, ownProps) {
  let event = {};
  if (events.eventsDetails) {
    event = events.eventsDetails;
  }
  return {
    event: event,
    apidown: events.apidown,
    loading: events.loading,
  };
}
EventDetail.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default withRouter(connect(mapStateToProps, { fetchEvent })(EventDetail));
