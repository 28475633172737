import { FETCH_EVENTS, FETCH_EVENTS_TIMEOUT, FETCH_EVENT, FILTER_EVENTS, SET_SELECTIONID, SET_TITLE, SET_DATE, FETCH_EVENTS_OK, FETCH_EVENT_OK, FETCH_EVENT_TIMEOUT, FETCH_OBJETSLIE, FETCH_OBJETSLIE_OK, FETCH_OBJETSLIE_TIMEOUT} from '../actions/index';
import { SELECTION_HOME } from '../components/top_menu';

const initialState = {
  selectionId: '',
  title: '',
  history: [],
  date: '',
  apidown: false,
  loading: false,
};

const reducer = (state = initialState, action) => {
  var newSelectionId;
  newSelectionId = state.selectionId ? state.selectionId : SELECTION_HOME;
  switch (action.type) {
    case FETCH_EVENTS:
      let reloading;
      if (action.append){
        reloading = true;
      }else{
        reloading = false;
      }
      return {
        ...state,
        'loading': true,
        'reloading': reloading 
      };

    case FETCH_EVENTS_OK:
      let initialState;
      let hasMore=false;
      let count=0;
      for (let key in action.payload.data.objetsTouristiques) {
        count+=action.payload.data.objetsTouristiques[key].length;
      }
      if(count==0 || count%200!=0){
        hasMore= false;
      }else{
        hasMore= true;
      }
      if (action.append){
          initialState = Object.assign(state.initialState, action.payload.data.objetsTouristiques);
      }else{
        initialState = action.payload.data.objetsTouristiques;
      }
      return {
        'initialState': initialState,
        // fonction de action.append
        'currentState': initialState,
        'eventsDetails': state.eventsDetails,
        'selectionId': newSelectionId,
        'title': state.title,
        'history': state.history,
        'date': state.date,
        'apidown': false,
        'loading': false,
        'hasMore': hasMore,
        'reloading': false,
        'hasReload': action.append
      };

    case FETCH_EVENTS_TIMEOUT:
      return {
        ...state,
        'apidown': true,
        'loading': false,
        'reloading': false,
      };

    case FETCH_EVENT:
      return {
        ...state,
        'loading': true
      };

    case FETCH_EVENT_OK:
      const event = action.payload.data;
      const newEventsDetail = { ...state.eventsDetails };
      var date = Object.keys(event);
      newEventsDetail[date[0]] = event;
      newSelectionId = state.selectionId ? state.selectionId : SELECTION_HOME;
      return {
        'initialState': state.initialState,
        'currentState': state.currentState,
        'eventsDetails': event,
        'selectionId': newSelectionId,
        'title': state.title,
        'history': state.history,
        'date': state.date,
        'apidown': false,
        'loading': false,
      };

    case FETCH_EVENT_TIMEOUT:
      return {
        ...state,
        'apidown': true,
        'loading': false,
      };
      
    case FETCH_OBJETSLIE:
      return {
        ...state,
      };

    case FETCH_OBJETSLIE_OK:
      return {
        ...state,
        'objetLies': action.payload.data
      };

    case FETCH_OBJETSLIE_TIMEOUT:
      return {
        ...state,
      };

    case FILTER_EVENTS:
      newSelectionId = state.selectionId ? state.selectionId : SELECTION_HOME;
      return {
        'initialState': state.initialState,
        'currentState': action.payload,
        'eventsDetails': state.eventsDetails,
        'selectionId': newSelectionId,
        'title': state.title,
        'history': state.history,
        'date': state.date,
        'apidown': false,
        'hasMore': state.hasMore,
      };

    case SET_SELECTIONID:
      return {
        'initialState': state.initialState,
        'currentState': state.currentState,
        'eventsDetails': state.eventsDetails,
        'selectionId': action.payload,
        'title': state.title,
        'history': state.history,
        'date': state.date,
        'apidown': false
      };

    case SET_TITLE:
      return {
        'initialState': state.initialState,
        'currentState': state.currentState,
        'eventsDetails': state.eventsDetails,
        'selectionId': newSelectionId,
        'title': action.payload,
        'history': state.history,
        'date': state.date,
        'apidown': false
      };

    case SET_DATE:
      return {
        'initialState': state.initialState,
        'currentState': state.currentState,
        'eventsDetails': state.eventsDetails,
        'selectionId': newSelectionId,
        'title': state.title,
        'history': state.history,
        'date': action.payload,
        'apidown': false
      };

    default:
      return state;
  }
}
export default reducer;
