import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

/**
 * Usage: 
 * <Meta title="" description="" ogtitle="" ogimage="" ogurl="" ogtype="" ogtitle="" ogdescription="" ogimageurl="" fbAppId=""/>
 */
export default class Meta extends Component {
    constructor(props) {
        super(props);
    }
    


    render() {
        return (
            <div class="wrapper">
                <MetaTags>
                    {this.props.title &&
                    <title>{this.props.title}</title>
                    }
                    
                    {this.props.description &&
                    <meta name="description"               content={this.props.description} />    
                    }
                    
                    {this.props.ogtitle &&
                    <meta property="og:title"              content={this.props.ogtitle} />
                    }
                    
                    {this.props.ogimage &&
                    <meta property="og:image"              content={this.props.ogimage.replace('http:', 'https:')} />
                    }
                    
                    {this.props.ogurl &&
                    <>
                        <meta property="og:url"                content={this.props.ogurl} />
                        <link rel="canonical"                  content={this.props.ogurl} />
                    </>
                    }
                    
                    {this.props.ogtype &&
                    <meta property="og:type"               content={this.props.ogtype} />
                    }
                    
                    {this.props.ogdescription &&
                    <meta property="og:description"        content={this.props.ogdescription} />
                    }
                    
                    {this.props.ogimageurl &&
                    <meta property="og:image:url"          content={this.props.ogimageurl.replace('http:', 'https:')} />
                    }
                    
                    {this.props.fbAppId &&
                    <meta property="fb:app_id"             content={this.props.fbAppId} />
                    }
                </MetaTags>
            </div>
        );
    }

}
