import React, { Component } from 'react';
import { geolocated } from "react-geolocated";
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import Leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';
const geo_gold = '/assets/markers/geo-gold.png';
const ici = '/assets/markers/ici.png';
const iconGold = new Leaflet.Icon({
  iconUrl: geo_gold,
  iconSize: [25.5, 37.5], // size of the icon
  iconAnchor: [12.75, 37.5], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -37.5] // point from which the popup should open relative to the iconAnchor
});
const iconBlue = new Leaflet.Icon({
  iconUrl: ici,
  iconSize: [15, 15], // size of the icon
  iconAnchor: [7.5, 37.5], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -37.5] // point from which the popup should open relative to the iconAnchor
});

const MyPopupMarker = function(props) {
  return (
    <Marker position={props.position} icon={iconGold} key={`marker.${props.id}`}>
            <Popup key={`popup.${props.id}`}>
                    <p><a href={`/${props.selectionId}/${props.tab}/detail/${props.id}`} target={props.target} className={props.css} name={props.children}>{props.children}</a></p>
            </Popup>
        </Marker>
  );
};

const PositionMarker = function(props) {
  return (
    <Marker position={props.position} icon={iconBlue}>
            <Popup>
                    <p>Je suis ici</p>
            </Popup>    
        </Marker>
  );
};
const MyMarkersList = ({ markers }) => {
  const items = markers.map(function(marker, index) {
    return (
      <MyPopupMarker id={marker.key} key={index} children={marker.children} position={marker.position} date={marker.date} target={marker.target} css={marker.class} selectionId={marker.selectionId} tab={marker.tab}/>
    );
  });
  return <div style={{ display: 'none' }}>{items}</div>;
};

class CustomMap extends Component {
  constructor(props) {
    super(props);
    this.state={ zoom: 10 };
  }

  render = () =>{
    let myPosMarker = {};
    var { center, markers } = this.props;
    if (this.props.coords) {
      myPosMarker.position = [this.props.coords.latitude, this.props.coords.longitude];
    }
    return (
      <Map center={center} zoom={this.state.zoom} scrollWheelZoom={false} >
        <TileLayer
            attribution="&amp;copy <a href=&quot;https://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                minZoom={9}
                maxZoom={18}
        />
        <MyMarkersList markers={markers}  />
        {myPosMarker.position &&
            <PositionMarker position={myPosMarker.position} />
        }
      </Map>
    )
  };
};
export default geolocated()(CustomMap);
