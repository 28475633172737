import React, { Component } from 'react';

export default class PageNotFound extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="error page-not-found">
            Page not found
        </div>
        );
    }
}
