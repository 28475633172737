import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import CookieConsent from "react-cookie-consent";
import Nav from 'react-bootstrap/Nav';
import PropTypes from 'prop-types';
const logo = '/assets/picto/happiness.svg';
const top = '/assets/picto/arrow-w.svg';

class Footer extends Component {
    constructor(props) {
        super(props);
    }
    goTop = (event) =>{
        window.scrollTo(0, 0);
    }
    render = () =>{
        var key = "footer.done.today";
        var link = "footer.link.today";
        var target = "date";
        if (this.props.isEmpty && this.props.isEmpty === true) {
            if (this.props.tab) {
                switch (this.props.tab) {
                    case 'aujourdhui':
                        key = "footer.noresult.today";
                        link = "footer.link.today";
                        target = "semaine";
                        break;
                    case 'semaine':
                        key = "footer.noresult.week";
                        link = "footer.link.week";
                        target = "date";
                        break;
                    case 'weekend':
                        key = "footer.noresult.weekend";
                        link = "footer.link.weekend";
                        target = "date";
                        break;
                    case 'date':
                        key = "footer.noresult.date";
                        link = "footer.link.date";
                        target = "none"
                        break;
                    default:
                        break;
                }
            }
        }
        else {
            if (this.props.tab) {
                switch (this.props.tab) {
                    case 'aujourdhui':
                        key = "footer.done.today";
                        link = "footer.link.today";
                        target = "semaine";
                        break;
                    case 'semaine':
                        key = "footer.done.week";
                        link = "footer.link.week";
                        target = "date";
                        break;
                    case 'weekend':
                        key = "footer.done.weekend";
                        link = "footer.link.weekend";
                        target = "date";
                        break;
                    case 'date':
                        key = "footer.done.date";
                        link = "footer.link.date";
                        target = "none";
                        break;
                    default:
                        break;
                }
            }
        }
        const buttonCookieLabel = this.context.intl.formatMessage({ id: "button.cookie", defaultMessage: "I agree" });
        return (
            <div className="footer row">
                {this.props.islisting == 1 &&
                <div className="col-xs-8 col-xs-offset-2 footer-content">
                    <p><img decoding="async" loading="lazy" src={logo} alt="logo footer" width={50}/></p>
                    <p class="suite">
                        <FormattedMessage id={key}/>
                        {target != 'none' &&
                            <span><Nav.Link href={`/${this.props.selection}/${target}/liste`}><FormattedMessage id={link}/></Nav.Link></span>
                        }
                    </p>
                </div>
                }
                <div className="col-xs-2 push-top">
                   <img decoding="async" loading="lazy" src={top} alt="retour haut de page" width={50} onClick={this.goTop}/>
                </div>
                <CookieConsent
                    location="bottom"
                    style={{ background: "#2B373B" }}
                    buttonText={buttonCookieLabel}>
                    <FormattedMessage id={"msg.cookie"}/>
                </CookieConsent>
            </div>
        );
    }
}

Footer.contextTypes = {
    intl: PropTypes.object.isRequired
};

export default Footer;
