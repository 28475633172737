import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ReactDOM from 'react-dom';
import moment from 'moment';
import _ from 'lodash';
import PropTypes from 'prop-types';
import LocalizedDatePicker from './localized-datepicker';
import { DISPLAY_LIST, DISPLAY_MAP } from './event_list';
import { filterEvent, fetchEventsByPeriode, fetchEventsByFilters } from '../actions/index';
import { FILTER_THEMATIC, FILTER_PLACE } from '../actions/index';
export const LABEL_FILTRE_THEME = <FormattedMessage id="tri.theme"/>;
export const LABEL_FILTRE_LIEUX = <FormattedMessage id="tri.place"/>;
const CommuneSortRef = [
    'Cuers',
    'Collobrières',
    'Pierrefeu-du-Var',
    'La Londe-les-Maures',
    'Bormes-les-Mimosas',
    'Le Lavandou'
];

let isReset = false;
class FilterBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentURI: '',
            thematic: '',
            place: '',
            isReset: '',
        };
    }
    focusDiv = () =>{
        if (this.refs["filter-bar"]) {
            ReactDOM.findDOMNode(this.refs["filter-bar"]).focus();
        }
    }
    componentWillMount = () =>{
        if (this.props.events.currentState) {
            this.props.filterEvent(this.getFilters(), this.props.events.currentState);
        }
    }
    componentDidUpdate = () =>{
        if(this.props.events.hasReload){
            if (this.props.events.currentState) {
                this.props.filterEvent(this.getFilters(), this.props.events.currentState);
            }
        }
    }
    getThematics = () =>{
        let categories = [];
        let themes = [];
        let thematics = [];
        categories = _.map(this.props.events.currentState, function(day) {
            return _.map(day, function(event) {
                if (event.informationsFeteEtManifestation.categories)
                    return event.informationsFeteEtManifestation.categories.map((category) => category.libelleFr);
            });
        });
        categories.unshift('Autour du vin');
        // themes = _.map(this.props.events.currentState, function(day) {
        //     return _.map(day, function(event) {
        //         if (event.informationsFeteEtManifestation.themes)
        //             return event.informationsFeteEtManifestation.themes.map((theme) => theme.libelleFr);
        //     });
        // });
        var mergedThematics = [].concat.apply([], categories);
        // mergedThematics = mergedThematics.concat(themes);
        mergedThematics = [].concat.apply([], mergedThematics);
        mergedThematics = [...new Set(mergedThematics)];
        const label_theme = this.context.intl.formatMessage({ id: "tri.theme", defaultMessage: "By theme" });
        mergedThematics = [label_theme].concat(mergedThematics);
        mergedThematics = mergedThematics.filter((thematic) => { return thematic });
        return mergedThematics;
    }
    getPlaces = () =>{
        let places = [];
        places = _.map(this.props.events.currentState, function(day) {
            return _.map(day, function(event) {
                return event.localisation.adresse.commune.nom;
            });
        });
        var mergedPlaces = [].concat.apply([], places);
        mergedPlaces = [].concat.apply([], mergedPlaces);
        mergedPlaces = [...new Set(mergedPlaces)];
        if (mergedPlaces.length > 0) {
            mergedPlaces.sort(function(communeA, communeB) {
                var posA = CommuneSortRef.indexOf(communeA);
                var posB = CommuneSortRef.indexOf(communeB);
                return posA - posB;
            });
        }
        const label_place = this.context.intl.formatMessage({ id: "tri.place", defaultMessage: "By place" });
        return [label_place].concat(mergedPlaces);
    }
    renderThematic = (thematic, index) =>{
        return <option className="option" key={index} value={thematic}>{thematic}</option>;
    }
    renderPlace = (place, index) =>{
        return <option className="option" key={index} value={place}>{place}</option>;
    }
    getFilters = () =>{
        let filters = [];
        let filterTheme = {};
        let filterPlace = {};
        filterTheme.type = FILTER_THEMATIC;
        if (this.refs.byTheme) {
            filterTheme.value = this.refs.byTheme.value;
        }
        else {
            filterTheme.value = this.context.intl.formatMessage({ id: "tri.theme", defaultMessage: "By theme" })
        }
        filters.push(filterTheme);
        filterPlace.type = FILTER_PLACE;
        if (this.refs.byPlace) {
            filterPlace.value = this.refs.byPlace.value;
        }
        else {
            filterPlace.value = this.context.intl.formatMessage({ id: "tri.place", defaultMessage: "By place" });
        }
        filters.push(filterPlace);
        return filters;
    }
    filterByPlace = (event) =>{
        isReset = (this.refs.byPlace.value == this.context.intl.formatMessage({ id: "tri.place", defaultMessage: "By place" })) ? true : false;
        this.setState({
            currentURI: window.location.href,
            thematic: this.refs.byTheme.value,
            place: this.refs.byPlace.value,
        });
        this.props.filterEvent(this.getFilters(), this.props.events.initialState);
    }
    filterByThematic = (event) =>{
        isReset = (this.refs.byTheme.value == this.context.intl.formatMessage({ id: "tri.theme", defaultMessage: "By theme" })) ? true : false;
        this.setState({
            currentURI: window.location.href,
            thematic: this.refs.byTheme.value,
            place: this.refs.byPlace.value,
        });
        if (isReset) {
            this.props.filterEvent(this.getFilters(), this.props.events.initialState);
        }
        else {
            this.props.filterEvent(this.getFilters(), this.props.events.currentState);
        }
    }
    render = () =>{
        var thematics = this.getThematics();
        var places = this.getPlaces();
        const mapTarget = `/${this.props.selectionId}/${this.props.tab}/carte`;
        const listTarget = `/${this.props.selectionId}/${this.props.tab}/liste`;
        const now = this.props.events.date || moment(new Date()).format('YYYY-MM-DD');
        return (
            <div class="filter-bar">
               <div class="form-group">
                    <select className="form-control" id="" onChange={this.filterByThematic} ref="byTheme" value={this.state.thematic}>
                        {thematics.map(this.renderThematic)}
                    </select>
                </div>
                <div class="form-group">
                    <select className="form-control" id="sel2" onChange={this.filterByPlace} ref="byPlace" value={this.state.place}>
                        {places.map(this.renderPlace)}
                    </select>
                </div>
                <div class="form-group">
                {this.props.display == DISPLAY_LIST  &&
                    <Link to={mapTarget} name="carte" className="btn btn-1 picto-map">Carte</Link>
                }
                {this.props.display == DISPLAY_MAP  && 
                    <Link to={listTarget} name="liste" className="btn btn-1 picto-square">Events</Link>
                }
                </div>
                {this.props.tab === 'date'  &&
                <div className="select-date">
                    <div className="begin"><FormattedMessage id="tri.date"/></div>
                    <div className="datepicker">
                        <LocalizedDatePicker 
                             selectedDate={now}
                             disabled
                             onDateSelect={ (date) => {
                                this.props.fetchEventsByFilters(date.format('YYYY-MM-DD'), date.add(365, 'days').format('YYYY-MM-DD'), this.props.selectionId, this.getFilters());
                             }}   
                        />
                    </div>
                </div>
                }
            </div>
        );
    }
}

FilterBar.contextTypes = {
    intl: PropTypes.object.isRequired
};

function mapStateToProps({ events }) {
    return { events };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ filterEvent, fetchEventsByPeriode, fetchEventsByFilters }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterBar);