import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Territoire from './page/territoire';
import MentionLegal from './page/mention-legal';
import SplashScreen from './components/splashscreen';
import { addLocaleData, IntlProvider } from 'react-intl';
import registerServiceWorker from './registerServiceWorker';
import fr from 'react-intl/locale-data/fr';
import en from 'react-intl/locale-data/en';
import de from 'react-intl/locale-data/de';
import { routes } from './routes.js';
import PageNotFound from './components/page_not_found';
import thunk from 'redux-thunk';
import rootReducer from './reducers/index.js';
import './sass/custom.scss';
import 'bootstrap/dist/css/bootstrap.css';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-122094047-1');
// ReactGA.pageview(window.location.pathname + window.location.search); 



import localeData from './conf/i18n.json';
addLocaleData([...fr, ...en, ...de]);

function renderRoute(route) {
  return (
    <Route 
        exact 
        path={route.path} 
        component={route.component} 
        key={route.key}
      />
  );
}

// Matomo
import { MatomoProvider, createInstance, useMatomo } from '@datapunt/matomo-tracker-react';
const instance = createInstance({
  urlBase: 'https://analytics.websenso.com',
  siteId: 242
});


const language = (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;

// Split locales with a region code 
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
export { languageWithoutRegionCode };
// console.log(language, "language");
// console.log(languageWithoutRegionCode, "languageWithoutRegionCode");
// Try full locale, try locale without region code, fallback to 'en'
const messages = localeData[languageWithoutRegionCode] || localeData[language] || localeData.en;

//const createStoreWithMiddleware = applyMiddleware(ReduxPromise)(createStore);
const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
);

const routeDef = routes();

// console.log(routeDef, 'routeDef');
//Désactiver pour la mise en prod du 10/07/2018
//<Route exact path="/territoire" component={Territoire}/>  
ReactDOM.render(
  <IntlProvider locale={language} messages={messages}>
    <MatomoProvider value={instance}>
      <Provider store={store}>
        <BrowserRouter>
            <Switch>
              <Route strict exact path="/" component={SplashScreen}/>  
              <Route exact path="/mentions-legales" component={MentionLegal}/>
              <Route exact path="/territoire" component={Territoire}/>
              {routeDef.map(renderRoute)}
              <Route component={PageNotFound}/>  
            </Switch>
            <Route path="/" render={({location}) => {
                
                // Google Analytics
                if (typeof window.ga === 'function') {
                  window.ga('set', 'page', location.pathname + location.search);
                  window.ga('send', 'pageview');
                }
              
                // Matomo
                window._paq.push(['setDocumentTitle', document.title]);
                window._paq.push(['setCustomUrl', location.pathname + location.search]);
                window._paq.push(['trackPageView']);
                window._paq.push(['enableLinkTracking']); 

                return null;
                
              }} />
        </BrowserRouter>
      </Provider>
    </MatomoProvider>
  </IntlProvider>, document.querySelector('.container'));


registerServiceWorker();
