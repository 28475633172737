import React, {Component} from 'react';
import { Link, MemoryRouter } from 'react-router-dom';
import territoireJson from '../conf/territoire.json';
import TopMenu from '../components/top_menu';

export default class Territoire extends Component {
    constructor(props) {
        super(props);
    }
    onBack = () =>{
        this.props.history.goBack();
    }
    render = () =>{
        var arr = [];
        Object.keys(territoireJson).forEach(function(key) {
            arr.push(territoireJson[key]);
        });
        return (
            <div className="page territoire">
                <TopMenu go={ url => this.props.history.push(url) } back = {this.onBack}/>
                <div className="row territoires">
                    {arr[0].map(this.renderTerritoire)}
                </div>
            </div>
        );
    }
    renderTerritoire = (territoire) =>{
        return (
          <div className="col-xs-6 item-territoire" key={territoire.title}>
              <div className="image" > <a href={`${territoire.url}`} target="_blank"><img alt="image territoire" src={territoire.image}/></a></div>
              <a href={`${territoire.url}`} target="_blank" className={territoire.className}>{territoire.title}</a>
          </div>  
        );
    }
}